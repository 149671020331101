.header {
    z-index: 999;
    width: 100%;
    background-color: rgb(249, 233, 233);
    border-bottom: 1px solid #F2F2F2;
    min-height: 80px;
    display: flex;
    padding: 25px;
}
.container {
    width: 100vw;
    height: 100vh;
    background-color: rgb(249, 233, 233);
}
.containerCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content_page {
    padding: 80px 0 0 0;
}
.containerContentBody {
    min-height: 500px;
    background-color: rgb(249, 233, 233);
}
.containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.containerHeaderLogo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.containerHeaderMenu {
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerHeaderMenuTouch {
    padding: 10px 15px;
    opacity: 1;
    transition: 0.3s;
}
.containerHeaderMenuTouch:hover {
    opacity: 0.5;
}
.containerHeaderMenuTouchBtn {}
.containerHeaderMenuTouchBtnText {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}
.containerHeaderRight {
    padding-left: 15px;
}

footer {
    padding: 30px 0;
    border-top: 1px solid #F2F2F2;
    background-color: rgb(249, 233, 233);
}
.containerFooterRow {
    flex: 1;
    padding: 0 20px;
}
.containerFooterMenuTouch {
    padding: 10px 15px;
    opacity: 1;
    transition: 0.3s;
}
.containerFooterMenuTouch:hover {
    opacity: 0.5;
}
.containerFooterMenuTouchBtn {

}
.containerFooterMenuTouchBtnText {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}
.containerFooterLogo {
    margin-bottom: 20px;
}
.containerFooterPhone {
    margin-bottom: 20px;
}
.containerFooterPhoneText {
    color: #979797;
    font-size: 22px;
    line-height: 26px;
}
.containerFooterContactForm {}
.containerFooterMenu {
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerFooterMenuTouch {
    padding: 10px 15px;
    opacity: 1;
    transition: 0.3s;
}
.containerFooterMenuTouch:hover {
    opacity: 0.5;
}
.containerFooterCopy {
    padding: 0 25px;
    padding-top: 15px;
}
.containerFooterCopyText {
    color: #979797;
    font-size: 14;
    letter-spacing: -0.04rem;
    font-weight: 400;
}

.box-shadow-default {
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}