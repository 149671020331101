/* ButtonUI */
.componentBtn_Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #313131;

    color: #fff;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

    border-radius: 5px;
    border: 1px solid #313131;
    cursor: pointer;

    opacity: 1;
    transition: 0.3s;
    padding: 6px 15px;
}
.componentBtn_Btn:hover {
    opacity: 0.5;
}
.componentBtn_BtnDisable {
    opacity: 0.5;
}
.componentBtn_ContainerLoader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* InputUI */
.component_InputUI_input {
    flex: 1;
    display: block;
    border: 1px solid #D9D9DE;
    background-color: "transparent";
    padding: 4px 10px;
    border-radius: 4px;

    color: #000;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
}
.component_InputUI_ErrorBlock {
    margin-top: 5px;
    color: rgb(237, 28, 36);
    font-size: 14px;
    text-align: left;
}

/* react-datepicker */
.react-datepicker-popper {
    z-index: 9999 !important;
}
.react-datepicker-wrapper {
    background-color: #D9D9D9;
    border-radius: 8px;
}
.react-datepicker__view-calendar-icon input {
    background-color: transparent;
    width: 100%;
    border: 0;
    margin-bottom: 2px;
    margin-top: 2px;
}


/* react-table */

.table-wrapper {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    white-space: nowrap;
    padding: 0.5rem;
}

th {
    background: rgba(0, 0, 0, 0.1);
}

tbody tr:nth-child(2n) {
    background: rgba(0, 0, 0, 0.05);
}

/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    z-index: 999999999;
    display: none;
}
.modal .modal_container {
    max-width: 600px;
    background: #fff;
    border-radius: 9px;
    padding: 30px;
    margin: 60px auto;
}
.modal .modal_header {
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
}
.modal .modal_header_text {
    flex: 1;
}
.modal .modal_content {
    padding: 16px 0;
}

.modal .modal_footer {
    display: flex;
}
.modal .modal_footer .componentBtn_Btn {
    margin-right: 16px;
}