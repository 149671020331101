* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    font-family: Inter, Arial, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, p {
    margin: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.flex {
    display: flex;
}
.flex-1 {
    flex: 1
}
.flex-center {
    align-items: center;
    justify-content: center;
}

label {
    display: inline-block;
    margin-bottom: 0;
    color: #fff;
    letter-spacing: -0.04rem;
    font-size: 16px;
    line-height: 1.4;
}

textarea {
    overflow: auto;
    resize: vertical;
}

.form-group {
    margin-bottom: 1rem;
}